import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title }) => {
    const description = 'ネイルならCuloreで。Culoreの最新情報をお届けします。オンライン予約もこちらから。'
    const url = 'https://www.culore.net/'
    const imageUrl = 'https://thirosue.github.io/hosting-image/booking/screenshot.png'

    return (
        <Helmet>
            <html lang="ja" />
            <title>{title}</title>
            <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width"
            />
            <meta name="Description" content={description} />
            <meta property="og:site_name" content={process.env.GATSBY_SALON_NAME} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={url} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="ja_JP" />
            <meta property="og:image" content={imageUrl} />
        </Helmet>
    )
}

export default Seo;