/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:43925e14-62d7-4449-996b-61ea26e365a3",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_EuqmzvkTj",
    "aws_user_pools_web_client_id": "5b3nko7vpo6eol7jpa6nlu10l9",
    "oauth": {
        "domain": "bookingf4b742b8-f4b742b8-prod.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.culore.net/",
        "redirectSignOut": "https://www.culore.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_dynamodb_all_tables_region": "ap-northeast-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "booking-prod",
            "region": "ap-northeast-1"
        },
        {
            "tableName": "calenderRelation-prod",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "table202359-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_appsync_graphqlEndpoint": "https://pzbrlu6bdjhn7fagtrav2yulfu.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-zuxb4s7yzvhs5mh42ixj72vile",
    "aws_cloud_logic_custom": [
        {
            "name": "booking",
            "endpoint": "https://gj88ug1g80.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
