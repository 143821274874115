import React from 'react'
import _ from 'lodash'
import GlobalContext from './global-context';
import AuthService from "../services/auth";
import { useSnackbar } from 'notistack';
import Progress from "../components/Progress";

// Global State Settings
const initialState = {
    signedIn: false,
    processing: false,
    session: {}
};

const initState = () => {
    let state = _.attempt(JSON.parse.bind(null, localStorage.getItem('state')));
    console.log(state)
    if (_.isError(state) || !state) {
        state = {
            ...{},
            ...initialState
        };
    }
    return {
        ...state,
        processing: false,
    }
}

const GlobalStateProvider = ({ children }) => {
    const [state, setState] = React.useState(initState);
    const { enqueueSnackbar } = useSnackbar();

    const updateState = value => {
        setState({ ...state, ...value })
    };

    const startProcess = () => {
        setState({ ...state, processing: true })
    };

    const endProcess = () => {
        setState({ ...state, processing: false })
    };

    const global = {
        state,
        updateState,
        startProcess,
        endProcess,
    }

    React.useEffect(() => {
        async function checkLogin() {
            await AuthService.getUser()
                .then(async user => {
                    const attributes = await AuthService.userAttributes(user);
                    const email_verified = attributes.some(attr => attr.Name === 'email_verified' && Boolean(attr.Value))
                    const vip = attributes.some(attr => attr.Name === 'zoneinfo' && JSON.parse(attr.Value).vip)
                    setState(prevState => ({
                        ...prevState,
                        signedIn: true,
                        session: {
                            username: user.attributes.name,
                            sub: user.attributes.sub,
                            email_verified,
                            vip
                        }
                    }))
                })
                .catch(() => {
                    if (state.signedIn) {
                        enqueueSnackbar('ログアウトしました');
                    }
                    setState({
                        ...{},
                        ...initialState
                    })
                })
        }

        checkLogin()
    }, []); // eslint-disable-line

    React.useEffect(() => {
        localStorage.setItem("state", JSON.stringify(state))
    }, [state]);

    return (
        <GlobalContext.Provider
            value={global}
        >
            {/*  processing start */}
            <Progress processing={state.processing} />
            {/*  processing end */}
            {children}
        </GlobalContext.Provider>
    );
}

export { GlobalStateProvider, initialState }