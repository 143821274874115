import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import BreakLine from '../../../components/atoms/breakLine'
import Payment from '../../../components/molecules/payment'
import Const from '../../../const'

const useStyles = makeStyles((theme) => ({
    sidebarShopBox: {
        padding: theme.spacing(2),
    },
    snsLink: {
        fontSize: '20px'
    },
    sidebarMapBox: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(3),
    },
    sidebarPaymentBox: {
        marginTop: theme.spacing(3),
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
}));

const Sidebar = (props) => {
    const classes = useStyles();
    const { social, other } = props;

    const handleApiLoaded = ({ map, maps }) => {
        new maps.Marker({
            map,
            position: Const.defaultLatLng,
        });
    };

    return (
        <>
            <Paper elevation={2} className={classes.sidebarShopBox}>
                <Typography align="center" variant="h6" gutterBottom>
                    ネイルサロン Culore
                </Typography>
                <Typography align="center">
                    <BreakLine value={Const.businessHours} />
                </Typography>
            </Paper>
            <Paper elevation={2} className={classes.sidebarMapBox}>
                <Typography align="center" variant="h6" gutterBottom>
                    アクセス
                </Typography>
                <Typography align="center">
                    <div style={{ height: '300px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyDkipBsNUpxgbmS-BdomtefmpT11b4i9rU' }}
                            defaultCenter={Const.defaultLatLng}
                            defaultZoom={16}
                            onGoogleApiLoaded={handleApiLoaded}
                        />
                    </div>
                </Typography>
                <Typography align="center">
                    <BreakLine value={Const.address} />
                </Typography>
            </Paper>
            <Grid className={classes.sidebarPaymentBox} container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Payment height={{ height: '290px' }} />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                SNS
            </Typography>
            {social.map((network) => (
                <>
                    <Link display="block" className={classes.snsLink} href={network.url} key={network.name}>
                        <Grid container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <FontAwesomeIcon size="lg" icon={network.icon} />
                            </Grid>
                            <Grid item>{network.name}</Grid>
                        </Grid>
                    </Link>
                    <Box mb="0.5rem" />
                </>
            ))}
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                その他
            </Typography>
            {other.map((network) => (
                <Link display="block" variant="body1" href={network.url} key={network.name}>
                    <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item>{network.name}</Grid>
                    </Grid>
                </Link>
            ))}
            <Box mb="2rem" />
        </>
    );
}

export default Sidebar;

Sidebar.propTypes = {
    archives: PropTypes.array,
    description: PropTypes.string,
    social: PropTypes.array,
    title: PropTypes.string,
};