import React, { lazy, Suspense } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider } from "@material-ui/core"
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Header from './header'
import Footer from './footer'
import { faLine, faInstagram } from '@fortawesome/free-brands-svg-icons'
import ConfirmProvider from '../../../context/confirmProvider'
import { GlobalStateProvider } from '../../../context/globalState'
import ErrorBoundary from '../../../errorboundary'
import useDocumentTitle from '../../../hooks/useDocumentTitle'
import Sidebar from './sidebar';
import Seo from './seo'
import theme from "../../../theme"

import './index.css'

const BookingForm = lazy(() => import('../../../components/pages/booking'));
const InquiryForm = lazy(() => import('../../dialog/inquiry'));

const useStyles = makeStyles((theme) => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#fff'
    },
    content: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
}));

const sidebar = {
    social: [
        { url: 'http://instagram.com/', name: 'Instagram', icon: faInstagram },
        { url: 'https://line.me/R/', name: 'Line', icon: faLine },
    ],
    other: [
        { url: '/privacy-policy', name: 'プライバシーポリシー' },
    ],
};

const Index = ({ location, children }) => {
    const classes = useStyles();
    const title = useDocumentTitle(location);

    // For booking
    const [openBooking, setOpenBooking] = React.useState(false)

    const handleClickOpenBooking = () => {
        setOpenBooking(true);
    };

    const handleCloseBooking = () => {
        setOpenBooking(false);
    };

    // For inquiry
    const [openInquiry, setOpenInquiry] = React.useState(false);

    const handleClickOpenInquiry = () => {
        setOpenInquiry(true);
    };

    const handleCloseInquiry = () => {
        setOpenInquiry(false);
    };

    const sections = [
        { title: 'トップページ', url: '/' },
        { title: 'メニュー・料金', url: '/menu' },
        // { title: 'お客様の声', url: '/reputation' },
        // { title: 'スタップ紹介', url: '/staff' },
        { title: 'ギャラリー', url: '/gallery' },
        { title: 'アクセス', url: '/map' },
        { title: 'よくあるお問い合わせ', url: '/faq' },
        { title: 'サロン予約', url: '#', handleOpen: handleClickOpenBooking },
        { title: 'お問い合わせ', url: '#', handleOpen: handleClickOpenInquiry },
    ];

    return (
        <ErrorBoundary>
            <Seo title={title} />
            <SnackbarProvider maxSnack={3}>
                <ThemeProvider theme={theme}>
                    <ConfirmProvider>
                        <GlobalStateProvider>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <CssBaseline />
                                <Suspense fallback={<></>}>
                                    <BookingForm open={openBooking} handleClose={handleCloseBooking} />
                                    <InquiryForm open={openInquiry} handleClose={handleCloseInquiry} />
                                </Suspense>
                                <Container className={classes.main} maxWidth="lg">
                                    <Header title="Culore" sections={sections} openBookingDialog={handleClickOpenBooking} />
                                    <Divider />
                                    <Box mb="1rem" />
                                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                                    <main>
                                        <Typography component="div" className={classes.content}>
                                            <Grid container spacing={5}>
                                                <Grid item xs={12} md={12} lg={9}>
                                                    {children}
                                                </Grid>
                                                <Grid item xs={12} md={12} lg={3}>
                                                    <Sidebar
                                                        social={sidebar.social}
                                                        other={sidebar.other}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Typography>
                                    </main>
                                </Container>
                                <Footer description="北小金駅 徒歩3分のネイルサロン｜若返って見えると人気のCulore" />
                            </MuiPickersUtilsProvider>
                        </GlobalStateProvider>
                    </ConfirmProvider>
                </ThemeProvider>
            </SnackbarProvider>
        </ErrorBoundary>
    )
}

export default Index;