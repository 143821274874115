import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
}));

const Payment = ({ height = { height: '240px' } }) => {
  const classes = useStyles();

  return (
    <Card style={height}>
      <CardHeader
        title={'お支払い方法'}
        titleTypographyProps={{ align: 'center' }}
        subheaderTypographyProps={{ align: 'center' }}
        className={classes.cardHeader}
      />
      <CardContent>
        <div className={classes.cardPricing}>
          <Typography color="textPrimary">
            当サロンは、現金、 クレジットカード（VISA／MasterCard／JCBなど）、交通系電子マネー（Suica／PASMO）、
            電子マネー（PayPay／ID／Apple Payなど）、デビットカードなどにてご支払いできます。
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default Payment;

Payment.propTypes = {
  height: PropTypes.object,
};