import React, { lazy, Suspense } from 'react'
import { navigate } from "gatsby"
import MediaQuery from 'react-responsive'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Link from '@material-ui/core/Link';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GlobalContext from '../../../context/global-context'
const SignInForm = lazy(() => import('../../../components/pages/auth/signin'));

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbarTitle: {
        flex: 1,
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto',
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0,
    },
    buttonIcon: {
        marginRight: '5px',
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    column: {
        flexBasis: '33.33%',
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const Header = (props) => {
    const classes = useStyles();
    const { sections, title, openBookingDialog } = props;

    const goMypage = () => navigate('/mypage')

    // For Sign in
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <GlobalContext.Consumer>
            {context => (
                <>
                    <Suspense fallback={<></>}>
                        <SignInForm open={open} handleClose={handleClose} />
                    </Suspense>
                    <Toolbar className={classes.toolbar}>
                        <Typography
                            component="h2"
                            variant="h5"
                            color="inherit"
                            align="center"
                            noWrap
                            className={classes.toolbarTitle}
                        >
                            {title}
                        </Typography>
                        <div className={classes.buttonIcon}>
                            <Tooltip title={'サロン予約'}>
                                <Button variant="outlined" size="small" onClick={openBookingDialog}>
                                    サロン予約
                                </Button>
                            </Tooltip>
                        </div>
                        <div className={classes.buttonIcon}>
                            <Tooltip title={context.state.signedIn ? 'マイページ' : 'ログイン'}>
                                <Button variant="outlined" size="small" onClick={context.state.signedIn ? goMypage : handleOpen}>
                                    {context.state.signedIn ? 'マイページ' : 'ログイン'}
                                </Button>
                            </Tooltip>
                        </div>
                    </Toolbar>
                    <MediaQuery minDeviceWidth={1023}> {/* iPad Pro以上 */}
                        <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
                            {sections.map((section) => (
                                <Tooltip key={section.title} title={section.title}>
                                    <Link
                                        color="inherit"
                                        noWrap
                                        key={section.title}
                                        variant="body2"
                                        href={section.url}
                                        className={classes.toolbarLink}
                                        onClick={section.handleOpen}
                                    >
                                        {section.title}
                                    </Link>
                                </Tooltip>
                            ))}
                        </Toolbar>
                    </MediaQuery>
                    <MediaQuery maxDeviceWidth={1023}> {/* iPad Pro以下 */}
                        <div className={classes.root}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                >
                                    <div className={classes.column}>
                                        <Typography className={classes.heading}>メニュー</Typography>
                                    </div>
                                </AccordionSummary>
                                {sections.map((section) => (
                                    <React.Fragment key={section.title}>
                                        <Tooltip title={section.title}>
                                            <AccordionDetails className={classes.details}>
                                                <Link
                                                    color="inherit"
                                                    noWrap
                                                    key={section.title}
                                                    variant="body2"
                                                    href={section.url}
                                                    onClick={section.handleOpen}
                                                >
                                                    {section.title}
                                                </Link>
                                            </AccordionDetails>
                                        </Tooltip>
                                    </React.Fragment>
                                ))}
                            </Accordion>
                        </div>
                    </MediaQuery>
                </>
            )}
        </GlobalContext.Consumer>
    )
}

export default Header;

Header.propTypes = {
    sections: PropTypes.array,
    title: PropTypes.string,
    openBookingDialog: PropTypes.func,
};