import React from 'react';

const BreakLine = ({ value }) => {
    return <>
        {value.split('\n').map((str, index) => (
            <React.Fragment key={index}><span dangerouslySetInnerHTML={{ __html : str }} /><br /></React.Fragment>
        ))}
    </>
}

export default BreakLine;